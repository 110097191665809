#testimonials p {
    font-size: 16px;
    line-height: 24px;
}

.blockquote{
    padding-right: 30px;
}

.myquote{
    margin-bottom: 10px;
}

